import api from 'lib/api';
export var GET_GUARDIAN_STUDENT_DOCUMENTATIONS = 'GET_GUARDIAN_STUDENT_DOCUMENTATIONS';
export var GET_GUARDIAN_STUDENT_DOCUMENTATION = 'GET_GUARDIAN_STUDENT_DOCUMENTATION';
export var CLEAR_GUARDIAN_STUDENT_DOCUMENTATION = 'CLEAR_GUARDIAN_STUDENT_DOCUMENTATION';
export var GET_GUARDIAN_STUDENT_DOCUMENTATION_FILE = 'GET_GUARDIAN_STUDENT_DOCUMENTATION_FILE';
export var GET_GUARDIAN_SECTION_DOCUMENTATIONS = 'DOCUMENTATIONS_GET_GUARDIAN_SECTION_DOCUMENTATIONS';
export var getGuardianStudentDocumentations = function getGuardianStudentDocumentations(studentId, page, size, unread) {
  return {
    type: GET_GUARDIAN_STUDENT_DOCUMENTATIONS,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/Students/".concat(studentId, "/Documentations/page/").concat(page, "/size/").concat(size).concat(unread ? '?&filter=status:unread' : '')).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getSectionDocumentations = function getSectionDocumentations(studentId, sectionId, page, size) {
  return {
    type: GET_GUARDIAN_SECTION_DOCUMENTATIONS,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/Students/".concat(studentId, "/Documentations/page/").concat(page, "/size/").concat(size, "?sectionId=").concat(sectionId)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getGuardianStudentDocumentation = function getGuardianStudentDocumentation(studentId, id) {
  return {
    type: GET_GUARDIAN_STUDENT_DOCUMENTATION,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/Students/".concat(studentId, "/Documentation/").concat(id)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var clearGuardianStudentDocumentations = function clearGuardianStudentDocumentations() {
  return {
    type: CLEAR_GUARDIAN_STUDENT_DOCUMENTATION,
    payload: true
  };
};
export var getGuardianStudentDocumentFile = function getGuardianStudentDocumentFile(studentId, id, fileId) {
  return {
    type: GET_GUARDIAN_STUDENT_DOCUMENTATION_FILE,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/Students/".concat(studentId, "/Documentation/").concat(id, "/file/").concat(fileId)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};