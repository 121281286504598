import api from '../lib/api';
export var GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export var GET_UNREAD_NOTIFICATIONS = 'GET_UNREAD_NOTIFICATIONS';
export var POST_REGISTER_PUSH = 'POST_REGISTER_PUSH';
export var UPDATE_NOTIFICATION_CONTENTREAD = 'UPDATE_NOTIFICATION_CONTENTREAD';
export var postRegisterPush = function postRegisterPush(data) {
  return {
    type: POST_REGISTER_PUSH,
    payload: new Promise(function (resolve) {
      api.post("pushnotifications/register", data).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getNotifications = function getNotifications(studentId) {
  return {
    type: GET_NOTIFICATIONS,
    payload: new Promise(function (resolve) {
      api.get("notifications").then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getUnreadNotifications = function getUnreadNotifications(studentId) {
  return {
    type: GET_UNREAD_NOTIFICATIONS,
    payload: new Promise(function (resolve) {
      api.get("notifications?status=unread").then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var updateNotificationContentRead = function updateNotificationContentRead(unreadList) {
  return {
    type: UPDATE_NOTIFICATION_CONTENTREAD,
    payload: new Promise(function (resolve) {
      api.put("notifications", unreadList).then(function (response) {
        resolve(response.data);
      });
    })
  };
};